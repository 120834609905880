import React, { Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import requestStore from '~/stores/requestStore';

import Divider from '~ui/Divider';

import EditField from '~/components/Base/EditField';
import AddressBlockEditing from '~/components/Items/Estate/EditBlocks/AddressBlockEditing';
import PriceBlockEditing from '~/components/Items/Estate/EditBlocks/PriceBlockEditing';
import PropertyBlockEditing from '~/components/Items/Estate/EditBlocks/PropertyBlockEditing';
import HandlerEditingBlockWrapper, { HandlerEditingWrappedProps } from '~/components/Items/HandlerEditingBlockWrapper';

type RequestEstateEditingProps = RouteComponentProps<{}> & HandlerEditingWrappedProps;

const RequestEstateEditing = (props: RequestEstateEditingProps) => {
    const handleDescription = ({ value }: { name: string; value: string }) => {
        estateStore.setEditingItem(CREATING_ITEM_ID, { description: value });
    };

    const {
        editingItem: { type }
    } = requestStore.getItem(CREATING_ITEM_ID);

    const { description } = estateStore.getItem(CREATING_ITEM_ID).editingItem;

    return (
        <>
            <AddressBlockEditing estate_id={CREATING_ITEM_ID} />
            <PropertyBlockEditing item_id={CREATING_ITEM_ID} simpleMode={true} />

            <EditField.Area name="description" label="Описание" value={description} onChange={handleDescription} />

            <Divider />
            <PriceBlockEditing type={type} item_id={CREATING_ITEM_ID} />
        </>
    );
};

export default HandlerEditingBlockWrapper(requestStore, withRouter(observer(RequestEstateEditing)));
