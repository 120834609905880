import React from 'react';

import { observer } from 'mobx-react';

import callRateCategoryStore from '~/stores/cell/callRateCategoryStore';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import Button from '~ui/Button';
import TextInput from '~ui/TextInput';

type CategoryOptionItemEditingProps = {
    category_id: number;
    index: number;
};

const CategoryOptionItemEditing = observer(({ category_id, index }: CategoryOptionItemEditingProps) => {
    const { options } = callRateCategoryStore.getItem(category_id).editingItem;

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        callRateCategoryStore.changeArrayValue(category_id, 'options', index, 'title', event.target.value);
    };

    const handleRemove = () => {
        const newOptions = [...options];
        newOptions.splice(index, 1);
        callRateCategoryStore.setEditingItem(category_id, { options: newOptions });
    };

    const { title } = options[index];

    return (
        <ListItem>
            <TextInput
                label="Опция"
                size="small"
                value={title}
                onChange={handleTitleChange}
                extraLabel={<Button tooltip="Убрать опцию" onClick={handleRemove} color="secondary" size="small" icon={<RemoveIcon />} />}
            />
        </ListItem>
    );
});

type CallRateCategoryOptionsEditingProps = {
    category_id: number;
};

const CallRateCategoryOptionsEditing = ({ category_id }: CallRateCategoryOptionsEditingProps) => {
    const { options } = callRateCategoryStore.getItem(category_id).editingItem;

    const handleAdd = () => {
        callRateCategoryStore.setEditingItem(category_id, {
            options: [...options, { category_id, category_option_id: null, title: '', enable: true }]
        });
    };

    return (
        <>
            <List dense>
                {options.map(({ title }, index) => (
                    <CategoryOptionItemEditing key={`option-${index}`} category_id={category_id} index={index} />
                ))}
            </List>
            <Button startIcon={<AddIcon />} onClick={handleAdd} variant="outlined">
                добавить опцию
            </Button>
        </>
    );
};

export default observer(CallRateCategoryOptionsEditing);
